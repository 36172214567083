import axios from "axios";


window.axios = require("axios");
export const adminRoot = "/app";

//  export const img_baseUrl = 'https://staging-wesal.com/wesal/public/Images';
//   axios.defaults.baseURL = 'https://staging-wesal.com/wesal/admin';



//  export const img_baseUrl = 'http://127.0.0.1:8000/Images';
//    axios.defaults.baseURL = 'http://127.0.0.1:8000/admin';


 export const img_baseUrl = 'https://wesalinternational.com/api/public/Images';
 axios.defaults.baseURL = 'https://wesalinternational.com/api/admin/';



//  export const img_baseUrl = 'https://wesalinternational.com/api/public/Images';
//  axios.defaults.baseURL = 'https://wesal-staging.wesal.site/admin/';



axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("accessToken");


export default { axios };
